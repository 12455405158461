<!--
 * @Description: 设备运维管理 设备管理 卡口监控 bayonetMonitor
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="卡口监控编号">
              <el-input v-model="searchForm.deviceId"
                        placeholder="请输入卡口监控编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="卡口监控名称">
              <el-input v-model="searchForm.deviceName"
                        placeholder="请输入卡口监控名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="卡口监控类型">
              <el-select v-model="searchForm.videoType"
                         placeholder="全部">
                <el-option v-for="item in videoTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="卡口监控地址">
              <el-input v-model="searchForm.address"
                        placeholder="请输入卡口监控地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="厂商">
              <el-input v-model="searchForm.vendor"
                        placeholder="厂商"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="设备状态">
              <el-select v-model="searchForm.status"
                         placeholder="全部">
                <el-option v-for="item in statusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>卡口监控</span>
        <el-row class="tableTitleButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px">
            <a :href="exportFile"
               download=""
               style="color:white">导 出</a>
          </el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  :tableFun='tableFun'
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="4">
                    <el-button type="text"
                               @click="handleClickLiftBrake(scope.row)"
                               size="medium">抬杆</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               @click="handleClickCloseGate(scope.row)"
                               size="medium">落杆</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               @click="handleClickEdit(scope.row)"
                               size="medium">修改</el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 抬杆备注 -->
    <el-dialog title="抬杆备注"
               :visible.sync="openDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="openFormList"
               :rules="rules"
               :inline="true"
               ref="openFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="openFormList.abnormalLiftReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickOpenConfirm()">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="openDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 落杆备注 -->
    <el-dialog title="落杆备注"
               :visible.sync="closeDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="closeFormList"
               :rules="rules"
               :inline="true"
               ref="closeFormList"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="closeFormList.abnormalLiftReason"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickCloseConfirm()">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="closeDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog title="新增卡口监控"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="addFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="addFormList.deviceId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="addFormList.deviceName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备类型"
                          prop="videoType">
              <el-select clearable
                         v-model="addFormList.videoType"
                         class="dt-form-width"
                         placeholder="请选择">

                <el-option v-for="(item, index) in videoTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="addFormList.version"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="addFormList.address"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="addFormList.vendor"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="addFormList.contact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="addFormList.longitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="addFormList.latitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="归属路段"
                          prop="roadId">
              <el-select clearable
                         v-model="addFormList.roadId"
                         class="dt-form-width"
                         placeholder="请选择"
                         @change="queryParkList">
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="addFormList.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="addFormList.parkId"
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名"
                          prop="loginAccount">
              <el-input v-model="addFormList.loginAccount"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="密码"
                          prop="loginPassword">
              <el-input v-model="addFormList.loginPassword"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP"
                          prop="ipAdd">
              <el-input v-model="addFormList.ipAdd"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="端口号"
                          prop="port">
              <el-input v-model="addFormList.port"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道号"
                          prop="channelId">
              <el-input v-model="addFormList.channelId"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickAddConfirm('addFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="addDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改卡口监控"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="editFormList"
               :rules="rules"
               :inline="true"
               ref="addFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="editFormList.deviceId"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="editFormList.deviceName"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备类型"
                          prop="videoType">
              <el-select clearable
                         v-model="editFormList.videoType"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in videoTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="editFormList.version"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="editFormList.address"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="editFormList.vendor"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="editFormList.contact"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editFormList.contactPhoneNumber"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="editFormList.longitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="editFormList.latitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="归属路段"
                          prop="roadId">
              <el-select clearable
                         v-model="editFormList.roadId"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="editFormList.remark"
                        class="dt-form-width"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="editFormList.parkId"
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名"
                          prop="loginAccount">
              <el-input v-model="editFormList.loginAccount"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="密码"
                          prop="loginPassword">
              <el-input v-model="editFormList.loginPassword"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP"
                          prop="ipAdd">
              <el-input v-model="editFormList.ipAdd"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="端口号"
                          prop="port">
              <el-input v-model="editFormList.port"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道号"
                          prop="channelId">
              <el-input v-model="editFormList.channelId"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="handleClickEditConfirm('editFormList')">确 定</el-button>
          <el-button type="info"
                     class="popBtn"
                     @click="editDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="卡口监控详情"
               :visible.sync="detailDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="queryTableList">
      <el-form :model="detailFormList"
               :rules="rules"
               :inline="true"
               ref="detailFormList"
               label-width="150px"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备编号"
                          prop="deviceId">
              <el-input v-model="detailFormList.deviceId"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称"
                          prop="deviceName">
              <el-input v-model="detailFormList.deviceName"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备类型"
                          prop="videoType">
              <el-select clearable
                         v-model="detailFormList.videoTypeDesc"
                         class="dt-form-width"
                         placeholder="请选择"
                         disabled>
                <el-option v-for="(item, index) in videoTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="detailFormList.version"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="detailFormList.address"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="detailFormList.vendor"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="detailFormList.contact"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="detailFormList.contactPhoneNumber"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="经度坐标"
                          prop="longitude">
              <el-input v-model="detailFormList.longitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度坐标"
                          prop="latitude">
              <el-input v-model="detailFormList.latitude"
                        class="dt-form-width"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="归属路段"
                          prop="roadId">
              <el-select clearable
                         v-model="detailFormList.roadId"
                         class="dt-form-width"
                         placeholder="请选择"
                         disabled>
                <el-option v-for="(item, index) in roadList"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注"
                          prop="remark">
              <el-input v-model="detailFormList.remark"
                        class="dt-form-width"
                        readonly
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="detailFormList.parkId"
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名"
                          prop="loginAccount">
              <el-input v-model="detailFormList.loginAccount"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="密码"
                          prop="loginPassword">
              <el-input v-model="detailFormList.loginPassword"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="IP"
                          prop="ipAdd">
              <el-input v-model="detailFormList.ipAdd"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="端口号"
                          prop="port">
              <el-input v-model="detailFormList.port"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道号"
                          prop="channelId">
              <el-input v-model="detailFormList.channelId"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="info"
                     class="popBtn"
                     @click="detailDialogVisible = false">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  computed: {
    exportFile: function () {
      return this.$downloadBaseUrl + "video/download12";
    }
  },
  data () {
    return {
      tableFun: { 'selection-change': this.handleChange, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '卡口监控编号',
        },
        {
          prop: 'deviceName',
          label: '卡口监控名称',
        },
        {
          prop: 'videoTypeDesc',
          label: '卡口监控类型',
        },
        {
          prop: 'address',
          label: '卡口监控地址',
        },
        {
          prop: 'vendor',
          label: '厂商',
        },
        {
          prop: 'statusDesc',
          label: '设备状态',
        },
      ],
      tableList: {
        list: [],
      },
      //查询表单
      searchForm: {
        videoTypeList: ["1", "2"], // 设备类型集合
      },
      pageNum: 1,
      pageSize: 15,// 分页参数

      addFormList: {},// 新增表单
      addDialogVisible: false, // 新增弹窗

      detailFormList: {},// 详情表单
      detailDialogVisible: false, // 详情弹窗

      editFormList: {},// 详情表单
      editDialogVisible: false, //修改弹窗

      openFormList: {},// 抬杆表单
      openDialogVisible: false,

      closeFormList: {},// 落杆表单
      closeDialogVisible: false,

      massDeleteList: [], // 批量删除绑定

      lineRowSave: {},

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",

      rules: {
        deviceId: [{ required: true, message: '请输入设备编号', trigger: 'blur' }],
        deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        videoType: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        version: [{ required: true, message: '请输入设备型号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入设备地址', trigger: 'blur' }],
        vendor: [{ required: true, message: '请输入设备厂商', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入经度坐标', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入纬度坐标', trigger: 'blur' }],
        roadId: [{ required: true, message: '请选择归属路段', trigger: 'change' }],
        ipAdd: [{ required: true, message: '请输入', trigger: 'blur' }],
        port: [{ required: true, message: '请输入', trigger: 'blur' }],
        loginAccount: [{ required: true, message: '请输入', trigger: 'blur' }],
        loginPassword: [{ required: true, message: '请输入', trigger: 'blur' }],
        channelId: [{ required: true, message: '请输入', trigger: 'blur' }],
        parkId: [{ required: true, message: '请选择', trigger: 'change' }],
      },

      roadList: [],// 路段列表
      parkList: [], // 归属停车场
      videoTypeList: [], // 
      statusList: [],   //设备状态
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.templateDl = this.$downloadBaseUrl + "video" + "/downloadTemplate";
    this.queryRoadList()
    this.queryVideoTypeList()
    this.queryStatusList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "video/upload";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 初始化数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$bayonetMonitor.queryVideoList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //搜索
    handleClickSearch () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.searchForm = {
        videoTypeList: ["1", "2"]
      }
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 抬杆
    handleClickLiftBrake (row) {
      this.lineRowSave = {}
      this.lineRowSave = row
      this.openFormList = {}
      this.openDialogVisible = true
    },
    // 抬杆确认
    handleClickOpenConfirm () {
      let that = this
      let info = {
        parkId: this.lineRowSave.parkId,
        parkName: this.lineRowSave.parkName,
        deviceId: this.lineRowSave.deviceId,
        deviceName: this.lineRowSave.deviceName,
        abnormalLiftReason: this.openFormList.abnormalLiftReason
      }
      this.$confirm('请再次确认是否抬杆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$bayonetMonitor.openGate(info).then(() => {
          that.openDialogVisible = false
          that.$message({ message: '抬杆成功', type: 'success' })
          that.queryTableList()
        })
      }).catch(() => {
        that.$message({ message: '抬杆已取消', type: 'info' })
        that.openDialogVisible = false
      })
    },
    // 落杆
    handleClickCloseGate (row) {
      this.lineRowSave = {}
      this.lineRowSave = row
      this.closeFormList = {}
      this.closeDialogVisible = true
    },
    // 落杆确认
    handleClickCloseConfirm () {
      let that = this
      let info = {
        parkId: this.lineRowSave.parkId,
        parkName: this.lineRowSave.parkName,
        deviceId: this.lineRowSave.deviceId,
        deviceName: this.lineRowSave.deviceName,
        abnormalLiftReason: this.closeFormList.abnormalLiftReason
      }
      this.$confirm('是否确定落杆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$bayonetMonitor.closeGate(info).then(() => {
          that.closeDialogVisible = false
          that.$message({ message: '落杆成功', type: 'success' })
          that.queryTableList()
        })
      }).catch(() => {
        that.$message({ message: '落杆已取消', type: 'info' })
        that.closeDialogVisible = false
      })
    },
    // 新增
    handleClickAdd () {
      this.addFormList = {}
      this.addDialogVisible = true
    },
    // 新增确认
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$bayonetMonitor.addVideo(this.addFormList).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '新增成功', type: 'success' });
              this.addDialogVisible = false
              this.queryTableList()
            }
          })
        }
      })
    },
    // 详情
    handleClickDetail (row) {
      this.detailFormList = row
      this.detailDialogVisible = true
    },
    // 修改
    handleClickEdit (row) {
      this.editFormList = {}
      row.videoType = JSON.stringify(row.videoType)
      this.editFormList = row
      this.editDialogVisible = true
    },
    // 修改确认
    handleClickEditConfirm () {
      this.$bayonetMonitor.updateVideo(this.editFormList).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({ message: '修改成功', type: 'success' });
          this.editDialogVisible = false
          this.queryTableList()
        }
      })
    },
    // 删除
    handleClickDelete (row) {
      this.$confirm('是否删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$bayonetMonitor.deleteVideo([{ deviceId: row.deviceId }]).then(res => {
          if (res.resultCode == 2000) {
            this.$message({ message: '删除成功', type: 'success' });
            this.queryTableList()
          }
        })
      });
    },
    // 多选
    handleChange (val) {
      let arr = []
      val.forEach((item) => {
        arr.push({ deviceId: item.deviceId })
      })
      this.massDeleteList = arr
    },
    // 批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确定批量删除设备?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = this.massDeleteList
          this.$bayonetMonitor.deleteVideo(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    queryVideoTypeList () {
      let info = {
        "columnName": ["value", "name"],
        "tableName": "tb_dict",
        whereStr: [
          {
            colName: 'parent_id',
            value: '29E4138B72C94B838D8B32119D850F30',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        response.resultEntity.forEach(item => {
          if (item.code === "1" || item.code === "2")
            this.videoTypeList.push(item)
        })
      })
    },
    queryRoadList () {
      let info = {
        "columnName": ["road_code", "road_name"],
        "tableName": "tb_road",
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.roadList = response.resultEntity
      })
    },
    // 根据路段获取停车场
    queryParkList (val) {
      let info = {
        columnName: ["park_id", "park_name"],
        tableName: "tb_park",
        "whereStr": [
          {
            "colName": "road_code",
            "value": val
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.parkList = res.resultEntity
      })
    },
    // 设备状态
    queryStatusList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'BE5701825A9D444F959E53CC17F664A8G',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.statusList = res.resultEntity
      })
    },
  },

  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-top: 20px;
      padding-left: 110px;
    }
    .buttonArea {
      display: flex;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        display: flex;
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-form-width {
  width: 230px;
}
.lineOne {
  margin-top: 25px;
  .title {
    display: inline-block;
    font-size: 16px;
    color: #70aee8;
    line-height: 18px;
  }
  .content {
    color: #ffffff;
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>


